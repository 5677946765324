<template>
  <svg
    width="90"
    height="90"
    viewBox="0 0 287 278"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse
      class="shaddow"
      cx="155"
      cy="257"
      rx="74"
      ry="7"
      fill="black"
      fill-opacity="0.47"
    />
    <g class="star">
      <path
        d="M146.995 107.582C150.342 101.066 159.658 101.066 163.005 107.582L180.91 142.433C182.217 144.976 184.654 146.748 187.477 147.204L226.155 153.463C233.387 154.633 236.265 163.493 231.103 168.69L203.491 196.488C201.475 198.516 200.544 201.382 200.982 204.208L206.982 242.927C208.103 250.167 200.567 255.642 194.029 252.338L159.059 234.667C156.507 233.377 153.493 233.377 150.941 234.667L115.971 252.338C109.433 255.642 101.897 250.167 103.018 242.927L109.018 204.208C109.456 201.382 108.525 198.516 106.509 196.488L78.8975 168.69C73.7346 163.493 76.6131 154.633 83.845 153.463L122.523 147.204C125.346 146.748 127.783 144.976 129.09 142.433L146.995 107.582Z"
        fill="#FFF96D"
      />
      <path
        d="M162.561 107.811L180.465 142.661C181.844 145.346 184.418 147.216 187.398 147.698L226.075 153.957C232.905 155.062 235.624 163.429 230.748 168.338L203.136 196.135C201.008 198.277 200.026 201.302 200.488 204.285L206.487 243.003C207.547 249.841 200.429 255.012 194.254 251.892L159.285 234.221C156.59 232.859 153.41 232.859 150.715 234.221L115.746 251.891C109.571 255.012 102.453 249.841 103.513 243.003L109.512 204.285C109.974 201.302 108.992 198.277 106.864 196.135L79.2522 168.338C74.3761 163.429 77.0948 155.062 83.9249 153.957L122.602 147.698C125.582 147.216 128.156 145.346 129.535 142.661L147.439 107.811C150.601 101.656 159.399 101.656 162.561 107.811Z"
        stroke="black"
        stroke-opacity="0.26"
      />
      <circle cx="134" cy="171" r="9" fill="black" />
      <circle cx="177" cy="171" r="9" fill="black" />
      <circle cx="131" cy="167" r="1" fill="white" />
      <circle cx="174" cy="167" r="1" fill="white" />
      <mask id="path-9-inside-1" fill="white">
        <path
          d="M172 195C172 199.243 170.314 203.313 167.314 206.314C164.313 209.314 160.243 211 156 211C151.757 211 147.687 209.314 144.686 206.314C141.686 203.313 140 199.243 140 195L156 195H172Z"
        />
      </mask>
      <path
        class="mouth"
        d="M172 195C172 199.243 170.314 203.313 167.314 206.314C164.313 209.314 160.243 211 156 211C151.757 211 147.687 209.314 144.686 206.314C141.686 203.313 140 199.243 140 195L156 195H172Z"
        fill="#FF331F"
        stroke="black"
        stroke-opacity="0.42"
        stroke-width="2"
        mask="url(#path-9-inside-1)"
      />
    </g>
    <path
      class="screen"
      d="M81 6H29.5L0 78H47L81 6Z"
      fill="url(#paint0_radial)"
    />
 
  </svg>
</template>

<script>
export default {};
</script>

<style lang="scss">
.screen {
  animation: slide 4s linear infinite;
}
.star {
  animation: bounce 4s linear infinite;
}
.shaddow {
  transform-origin: center center;
  animation: bounceShaddow 4s linear infinite;
}

@keyframes slide {
  from {
    transform: translatex(-10%);
  }
  to {
    transform: translatex(100%);
  }
}
@keyframes bounce {
  0%,
  40%,
  50%,
  60%,
  100% {
    transform: translatey(0%);
  }
  45%,
  55% {
    transform: translatey(-10%);
  }
}
@keyframes bounceShaddow {
  0%,
  40%,
  50%,
  60%,
  100% {
    opacity: 100%;
    transform: scale(1, 1);
  }
  45%,
  55% {
    opacity: 70%;
    transform: scale(0.8, 0.7) translatey(10%);
  }
}
</style>