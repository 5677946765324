<template>
  <div>
    <h3>{{ question.word }}</h3>
    <PrimeImage :src="question.word_pic" />
  </div>
</template>

<script>
export default {
  props: {
    question: { type: Object, required: true },
  },
};
</script>

<style lang="scss" scoped>
</style>