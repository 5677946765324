<template>
  <div class="row">
    <div
      v-for="(item, index) in questions"
      :key="item.id"
      :item="item"
      :index="index"
      style="width: auto"
    >
      <div
        :class="'col-md-' + 12 / questions.length"
        style="text-align: center"
      >
        <QuestionComponent :question="item" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    questions: { type: Array, required: true },
  },
  data() {
    return {
      deneme: "sdfsdfsdf",
    };
  },
};
</script>

<style lang="scss" scoped></style>
