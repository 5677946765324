import axios from "axios";

const login = async(username, password) => {
    try {
        const response = await axios
            .post("jwt-auth/v1/token", {
                username,
                password,
            })

        if (response.status) {
            console.log(response);
            console.log(response.data);
            console.log(response.data.user_id);
            localStorage.setItem("user_name", username);
            localStorage.setItem("user_id", response.data.user_id);
            localStorage.setItem("user_token", response.data.token);

            return response.data;
        }
        return null;



    } catch (e) {

        return e.response //UserNotFound
    }

};




export default {
    login,
};