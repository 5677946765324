import axios from "axios";


const getAll = async() => {
    try {
        const response = await axios.get(`kivicare/api/v1/meetinggame/get-meeting-game`);

        if (response.status == 200) {
            return response.data
        }
        return null

    } catch (e) {
        return null //UserNotFound
    }

};

export default {
    getAll,


};