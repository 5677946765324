import axios from "axios";


export const getAll = async () => {
    try {
        const response = await axios.get(`kivicare/api/v1/sheetquestion/get-sheetquestion`);

        if (response.status == 200) {
             return response.data
        }
        return null
    
    } catch (e) {
        return null //UserNotFound
    }

};
const getBySheetId = async (sheet_id) => {
    try {
        const response = await axios.get(`kivicare/api/v1/sheetquestion/get-sheetquestion-sheetid?sheet_id=` + sheet_id);

        if (response.status == 200) {
            return response.data
        }
        return null

    } catch (e) {
        return null //UserNotFound
    }

};
const getById = async (id) => {
    try {
        const response = await axios.get(`kivicare/api/v1/sheetquestion/get-sheetquestion-byid?id=` + id);

        if (response.status == 200) {
            return response.data
        }
        return null

    } catch (e) {
        return null //UserNotFound
    }

};
export default {
    getAll,
    getBySheetId,
    getById
 

};
